@use '@angular/material' as mat;
@include mat.elevation-classes();
@include mat.app-background();


/**********Custom Palette and Typography declarations**********/
  $ddolphin-palette: (
    50 : #f2f8ff,
      100 : #ddefff,
      200 : #c7e4ff,
      300 : #b1d9ff,
      400 : #a0d0ff,
      500 : #8fc8ff,
      600 : #87c2ff,
      700 : #7cbbff,
      800 : #72b4ff,
      900 : #60a7ff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #eaf3ff,
      contrast: (
          50 : #DEF,
          100 : #DEF,
          200 : #DEF,
          300 : #DEF,
          400 : #DEF,
          500 : #DEF,
          600 : #DEF,
          700 : #DEF,
          800 : #DEF,
          900 : #DEF,
          A100 : #DEF,
          A200 : #DEF,
          A400 : #DEF,
          A700 : #DEF,
      )
  );
  $ddolphin-typography: mat.m2-define-typography-config(
    $font-family: 'Roboto',
    $button: mat.m2-define-typography-level(14px, inherit, 500, $letter-spacing: normal)
  );

/**********App main theme**********/
  $my-app-primary: mat.m2-define-palette($ddolphin-palette);
  $my-app-accent: mat.m2-define-palette(mat.$m2-blue-grey-palette);
  $my-app-warn: mat.m2-define-palette(mat.$m2-red-palette);
  $my-app-theme: mat.m2-define-light-theme((
    color: (primary: $my-app-primary, accent: $my-app-accent, warn: $my-app-warn),
    typography: $ddolphin-typography
  ));
  @include mat.all-component-themes($my-app-theme);

/**********Dark theme**********/
  $dark-primary: mat.m2-define-palette(mat.$m2-blue-palette);
  $dark-accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, 900);
  $dark-warn: mat.m2-define-palette(mat.$m2-red-palette, 900);
  $dark-theme: mat.m2-define-dark-theme((
    color: (primary: $dark-primary, accent: $dark-accent, warn: $dark-warn),
    typography: mat.m2-define-typography-config(),
    density: 0
  ));
  .dark-theme {
    @include mat.all-component-colors($dark-theme);
  }

/**********Light Theme**********/
  $light-primary: mat.m2-define-palette($ddolphin-palette, 300);
  $light-accent: mat.m2-define-palette(mat.$m2-blue-grey-palette, 200);
  $light-warn: mat.m2-define-palette(mat.$m2-deep-orange-palette, 700);
  $light-theme: mat.m2-define-light-theme((
    color: (primary: $dark-primary, accent: $dark-accent, warn: $dark-warn),
    typography: $ddolphin-typography
  ));
  .light-theme {
    @include mat.all-component-colors($light-theme);
  }

/**********Vivid Theme**********/
  $vivid-primary: mat.m2-define-palette(mat.$m2-green-palette, 700);
  $vivid-accent: mat.m2-define-palette(mat.$m2-yellow-palette, 700);
  $vivid-warn: mat.m2-define-palette(mat.$m2-red-palette, 900);
  $vivid-theme: mat.m2-define-light-theme((
    color: (primary: $vivid-primary, accent: $vivid-accent, warn: $vivid-warn),
    typography: $ddolphin-typography
  ));
  .vivid-theme{
      @include mat.all-component-colors($vivid-theme);
  }

