/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  font-family: sans-serif;
}

h1 {
  text-align: center;
}

span>a>img {
	vertical-align: middle;
}

.fill-space {
  flex: 1 1 auto;
}

.backdrop-background {
	background: rgba(0, 0, 0, 0.8);
}

.ql-container {
	height: 300px !important;
}

.mat-cell:focus{
	outline: none;
}

mat-spinner{
	margin: 0 auto;
}

.avatar-acqua{
    background-color: aqua !important;
}

.avatar-beige{
	background-color: burlywood !important;
}

.avatar-blue{
	background-color: cadetblue !important;
}

.avatar-yellow{
	background-color: khaki !important;
}

.avatar-pink{
	background-color: lightpink !important;
}

.avatar-viole{
	background-color: blueviolet !important;
}

.avatar-orange{
	background-color: coral !important;
}

.avatar-green{
	background-color: lightgreen !important;
}

mat-card>a{
    text-decoration: none;
    color: inherit;
}